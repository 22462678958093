import { Injectable, inject } from "@angular/core";
import { HttpResponse } from "@angular/common/http";

import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { lowerFirst } from "lodash";

import { ServerGatewayBase } from "@logex/framework/lg-application";

import { SharedConfigService } from "@codman/shared/util-logex-framework-setup";
import { TimeRange } from "@codman/shared/util-filters";
import { IStatus } from "@codman/shared/types";
import { TrendInterval } from "@codman/shared/assets";
import {
    BenchmarkType,
    defaultProviders,
    IRegistryConfiguration,
    Provider,
} from "@codman/shared/feature-benchmarking";

import {
    FilterCounts,
    IOutcome,
    IOutcomeTrend,
    IPatientCount,
    IRegistryStructure,
    ITrendMoment,
    CategoryQueryParams,
    SEPARATORS,
    TimeRangeFilterRange,
    FilterParams,
    RangeQueryParams,
    StringQueryParams,
    NonFilterParams,
    ORG_ID_HEADER,
    OutcomesConfiguration,
    OutcomeConfiguration,
    ISubsetConfiguration,
    AnalysisUnit,
    PatientList,
    ISection,
    IProvider,
    IDataExportPage,
    ReferenceOutcome,
    FilterComparison,
    NumberQueryParams,
    ISortDirection,
    IBenchmarkConfiguration,
    IEventAnalysis,
    BenchmarkParams,
    TimeRangeQueryParams,
    IOutcomeWithSource,
    IOutcomeUniversal,
    IOutcomeTrendUniversal,
    FilterComparisonUniversal,
    IFilterOption,
    IEventAnalysisUniversal,
    IEventAnalysisInterval,
    IEventAnalysisIntervalReferenceWithSource,
    IDataExportPageUniversal,
    IDistributionPercentageOutcomeLevel2,
    ISectionFilter,
    IDistribution,
    IDistributionPercentageOutcome,
    ISankeyUniversal,
    ISankey,
} from "./data-access-api.types";

@Injectable({
    providedIn: "root",
})
export class DataAccessApiService extends ServerGatewayBase {
    protected _configService = inject(SharedConfigService);

    constructor() {
        super();
        const apiUrl = this._configService.getConfiguration()?.apiUrl;
        if (apiUrl != null) {
            this._setBaseUrl(apiUrl);
        } else {
            console.error("Cannot set application base API url based on configuration.");
        }
    }

    /**
     * Configuration
     */
    getRegistryStructure(
        registryId: string,
        organizationId: number,
    ): Observable<IRegistryStructure> {
        return this._get<IRegistryStructure>(`${registryId}/structure`, {
            headers: {
                [ORG_ID_HEADER]: organizationId.toString(),
            },
        });
    }

    getRegistryConfiguration(
        registryId: string,
        organizationId: number,
    ): Observable<IRegistryConfiguration> {
        return this._get<IRegistryConfiguration>(`${registryId}/registryconfiguration`, {
            headers: {
                [ORG_ID_HEADER]: organizationId.toString(),
            },
        }).pipe(
            // Backend sends First letter upercase and it is easier for us to work with lowecase ids
            map(result => {
                const benchmarkTypes = result.benchmarkTypes.map(benchmarkType =>
                    lowerFirst(benchmarkType),
                ) as BenchmarkType[];
                return { ...result, benchmarkTypes };
            }),
        );
    }

    getBenchmarkConfiguration(
        registryId: string,
        organizationId: number,
    ): Observable<IBenchmarkConfiguration> {
        return this._get<IBenchmarkConfiguration>(`${registryId}/benchmarkConfiguration`, {
            headers: {
                [ORG_ID_HEADER]: organizationId.toString(),
            },
        });
    }

    getSubsetConfiguration(
        registryId: string,
        subsetId: string,
        organizationId: number,
    ): Observable<ISubsetConfiguration> {
        // backend returning analysisLevel which is mapped for clearer naming in FE
        return this._get<ISubsetConfiguration & { analysisLevel: AnalysisUnit }>(
            `${registryId}/${subsetId}/subsetconfiguration`,
            {
                headers: {
                    [ORG_ID_HEADER]: organizationId.toString(),
                },
            },
        ).pipe(map(result => ({ ...result, analysisUnit: result.analysisLevel })));
    }

    getOutcomeConfiguration(
        registryId: string,
        subsetId: string,
        outcomeId: string,
        organizationId: number,
    ): Observable<OutcomeConfiguration> {
        return this._get<OutcomeConfiguration>(
            `${registryId}/${subsetId}/${outcomeId}/outcomeConfiguration`,
            {
                headers: {
                    [ORG_ID_HEADER]: organizationId.toString(),
                },
            },
        );
    }

    getPageOutcomes(
        registryId: string,
        subsetId: string,
        pageId: string,
        organizationId: number,
    ): Observable<ISection[]> {
        return this._get<ISection[]>(`${registryId}/${subsetId}/${pageId}/pageoutcomes`, {
            headers: {
                [ORG_ID_HEADER]: organizationId.toString(),
            },
        });
    }

    getOutcomesConfiguration(
        registryId: string,
        subsetId: string,
        organizationId: number,
    ): Observable<OutcomesConfiguration> {
        return this._get<OutcomesConfiguration>(`${registryId}/${subsetId}/outcomesConfiguration`, {
            headers: {
                [ORG_ID_HEADER]: organizationId.toString(),
            },
        });
    }

    /**
     * @deprecated use getBenchmarkConfiguration instead
     */
    getSharedProviders(registryId: string, organizationId: number): Observable<IProvider[]> {
        return this._get<IProvider[]>(
            `${registryId}/sharedproviders?providerId=${organizationId}`,
            {
                headers: {
                    [ORG_ID_HEADER]: organizationId.toString(),
                },
            },
        );
    }

    /**
     * Filters
     */
    getFilterCounts(
        registryId: string,
        subsetId: string,
        outcomeId: string,
        organizationId: number,
        timeRange?: TimeRange,
        filters?: FilterParams,
    ): Observable<FilterCounts> {
        return this.getFiltered<FilterCounts>(
            `${registryId}/${subsetId}/${outcomeId}/filtercount`,
            organizationId,
            filters,
            { timeRange },
        );
    }

    getYearsFilterCount(
        registryId: string,
        subsetId: string,
        organizationId: number,
    ): Observable<TimeRangeFilterRange> {
        return this._get<TimeRangeFilterRange>(`${registryId}/${subsetId}/yearsfilterrange`, {
            headers: {
                [ORG_ID_HEADER]: organizationId.toString(),
            },
        });
    }

    /**
     * Outcomes
     */
    getSingleOutcome(
        registryId: string,
        subsetId: string,
        outcomeId: string,
        organizationId: number,
        selectedProviders: Provider[],
        benchmarkApiParams: BenchmarkParams,
        timeRange?: TimeRange,
        filters?: FilterParams,
    ): Observable<IOutcomeUniversal> {
        return this.getFiltered<IOutcome>(
            `${registryId}/${subsetId}/${outcomeId}/outcome`,
            organizationId,
            filters,
            { timeRange, ...benchmarkApiParams },
        ).pipe(
            map(outcome => ({
                outcomeId: outcome.outcomeId,
                medicalParameterId: outcome.medicalParameterId,
                sharedProviders: outcome.sharedProviders,
                distributionParameterL1: outcome.distributionParameterL1,
                first: this._getDataOfProvider(outcome, selectedProviders, 0),
                second: this._getDataOfProvider(outcome, selectedProviders, 1),
                third:
                    selectedProviders.length > 2
                        ? this._getDataOfProvider(outcome, selectedProviders, 2)
                        : undefined,
            })),
        );
    }

    getSectionOutcomes(
        registryId: string,
        subsetId: string,
        pageId: string,
        sectionId: string,
        organizationId: number,
        selectedProviders: Provider[],
        benchmarkApiParams: BenchmarkParams,
        timeRange?: TimeRange,
        filters?: FilterParams,
    ): Observable<IOutcomeUniversal[]> {
        return this.getFiltered<IOutcome[]>(
            `${registryId}/${subsetId}/${pageId}/${sectionId}/outcomes`,
            organizationId,
            filters,
            { timeRange, ...benchmarkApiParams },
        ).pipe(
            map(outcomes => {
                let processedOutcomes: IOutcome[] = [];
                outcomes.forEach(outcome => {
                    if (
                        outcome.distributionLevel === 2 &&
                        outcome[selectedProviders[0]]?.type === "DistributionPercentage"
                    ) {
                        let processedOutcomesToAddFirst = this._getProcessedOutcomes(
                            selectedProviders,
                            outcome,
                            (outcome[selectedProviders[0]] as IDistributionPercentageOutcomeLevel2)
                                .distribution,
                            processedOutcomes,
                        );
                        processedOutcomes = processedOutcomes.concat(processedOutcomesToAddFirst);

                        let processedOutcomesToAddSecond = this._getProcessedOutcomes(
                            selectedProviders,
                            outcome,
                            (outcome[selectedProviders[1]] as IDistributionPercentageOutcomeLevel2)
                                .distribution,
                            processedOutcomes,
                        );
                        processedOutcomes = processedOutcomes.concat(processedOutcomesToAddSecond);

                        let processedOutcomesToAddThird = this._getProcessedOutcomes(
                            selectedProviders,
                            outcome,
                            selectedProviders[2]
                                ? (
                                      outcome[
                                          selectedProviders[2]
                                      ] as IDistributionPercentageOutcomeLevel2
                                  ).distribution
                                : undefined,
                            processedOutcomes,
                        );
                        processedOutcomes = processedOutcomes.concat(processedOutcomesToAddThird);
                    } else {
                        processedOutcomes.push(outcome);
                    }
                });
                return processedOutcomes.map(outcome => ({
                    outcomeId: outcome.outcomeId,
                    variableId: outcome.variableId,
                    medicalParameterId: outcome.medicalParameterId,
                    sharedProviders: outcome.sharedProviders,
                    distributionParameterL1: outcome.distributionParameterL1,
                    distributionParameterL2: outcome.distributionParameterL2,
                    distributionLevel: outcome.distributionLevel,
                    first: this._getDataOfProvider(outcome, selectedProviders, 0),
                    second: this._getDataOfProvider(outcome, selectedProviders, 1),
                    third:
                        selectedProviders.length > 2
                            ? this._getDataOfProvider(outcome, selectedProviders, 2)
                            : undefined,
                }));
            }),
        );
    }

    private _getProcessedOutcomes(
        selectedProviders: Provider[],
        outcome: IOutcome,
        data: { [optionId: string]: IDistributionPercentageOutcome } | undefined,
        existingProcessedOutcomes: IOutcome[],
    ): IOutcome[] {
        let processedOutcomes: IOutcome[] = [];

        for (var variableId in data) {
            if (!data.hasOwnProperty(variableId)) continue;

            let processedOutcome: IOutcome = {
                outcomeId: outcome.outcomeId,
                variableId,
                medicalParameterId: outcome.medicalParameterId,
                sharedProviders: outcome.sharedProviders,
                ownProvider: outcome.ownProvider,
                distributionParameterL1: outcome.distributionParameterL1,
                distributionParameterL2: outcome.distributionParameterL2,
                distributionLevel: outcome.distributionLevel,
            };

            selectedProviders.forEach(provider => {
                const data = (outcome[provider] as IDistributionPercentageOutcomeLevel2)
                    ?.distribution;
                if (data && data[variableId])
                    processedOutcome[provider] = {
                        type: data[variableId].type,
                        distribution: data[variableId].distribution,
                        anonymized: false,
                        numberOfPatients: data[variableId].numberOfPatients,
                        numberOfProviders: data[variableId].numberOfProviders,
                        distributionLevel: data[variableId].distributionLevel,
                    };
                else {
                    processedOutcome[provider] = {
                        type: "DistributionPercentage",
                        distribution: {},
                        anonymized: false,
                        numberOfPatients: 0,
                        numberOfProviders: 0,
                        distributionLevel: 1,
                    };
                }
            });

            if (
                !existingProcessedOutcomes.find(
                    item => item.variableId === processedOutcome.variableId,
                )
            )
                processedOutcomes.push(processedOutcome);
        }

        return processedOutcomes;
    }

    getOtherOutcomeProviders(
        registryId: string,
        subsetId: string,
        outcomeId: string,
        organizationId: number,
        benchmarkApiParams: BenchmarkParams,
        timeRange?: TimeRange,
        filters?: FilterParams,
    ): Observable<ReferenceOutcome[]> {
        return this.getFiltered<ReferenceOutcome[]>(
            `${registryId}/${subsetId}/${outcomeId}/outcomesotherproviders`,
            organizationId,
            filters,
            { timeRange, ...benchmarkApiParams },
        );
    }

    getOutcomeTrend(
        registryId: string,
        subsetId: string,
        outcomeId: string,
        interval: TrendInterval,
        organizationId: number,
        selectedProviders: Provider[],
        benchmarkApiParams: BenchmarkParams,
        filters?: FilterParams,
    ): Observable<IOutcomeTrendUniversal[]> {
        return this.getFiltered<IOutcomeTrend[]>(
            `${registryId}/${subsetId}/${outcomeId}/trend/${interval}`,
            organizationId,
            filters,
            { ...benchmarkApiParams },
        ).pipe(
            map(outcomes =>
                outcomes.map(outcome => ({
                    year: outcome.year,
                    quarter: outcome.quarter,
                    month: outcome.month,
                    anonymized: outcome.anonymized,
                    first: this._getDataOfProvider(outcome, selectedProviders, 0),
                    second: this._getDataOfProvider(outcome, selectedProviders, 1),
                    third:
                        selectedProviders.length > 2
                            ? this._getDataOfProvider(outcome, selectedProviders, 2)
                            : undefined,
                })),
            ),
        );
    }

    getEventAnalysis(
        registryId: string,
        subsetId: string,
        outcomeId: string,
        organizationId: number,
        benchmarkApiParams: BenchmarkParams,
        timeRange?: TimeRange,
        filters?: FilterParams,
    ): Observable<IEventAnalysisUniversal> {
        return this.getFiltered<IEventAnalysis>(
            `${registryId}/${subsetId}/${outcomeId}/eventAnalysis`,
            organizationId,
            filters,
            { timeRange, ...benchmarkApiParams },
        ).pipe(
            map(event => {
                return {
                    eventName: event.eventName,
                    intervals: event.intervals.map(interval => ({
                        interval: interval.interval,
                        first: this._getEventAnalysisWithSource(interval),
                        second: this._getEventAnalysisWithSource(interval, true),
                    })),
                };
            }),
        );
    }

    getSankey(
        registryId: string,
        subsetId: string,
        outcomeId: string,
        organizationId: number,
        selectedProviders: Provider[],
        benchmarkApiParams: BenchmarkParams,
        timeRange?: TimeRange,
        filters?: FilterParams,
    ): Observable<ISankeyUniversal> {
        return this.getFiltered<ISankey>(
            `${registryId}/${subsetId}/${outcomeId}/outcome/sankey`,
            organizationId,
            filters,
            { timeRange, ...benchmarkApiParams },
        ).pipe(
            map(outcome => {
                return {
                    first: this._getSankeyWithSource(outcome, selectedProviders, 0),
                    second: this._getSankeyWithSource(outcome, selectedProviders, 1),
                    third:
                        selectedProviders.length > 2
                            ? this._getSankeyWithSource(outcome, selectedProviders, 2)
                            : undefined,
                };
            }),
        );
    }

    getFilterComparisons(
        registryId: string,
        subsetId: string,
        outcomeId: string,
        filterGroupId: string,
        organizationId: number,
        selectedProviders: Provider[],
        benchmarkApiParams: BenchmarkParams,
        timeRange?: TimeRange,
        measurementMoment?: string,
        filters?: FilterParams,
    ): Observable<FilterComparisonUniversal[]> {
        return this.getFiltered<FilterComparison[]>(
            `${registryId}/${subsetId}/${outcomeId}/filtercomparison/${filterGroupId}`,
            organizationId,
            filters,
            {
                timeRange,
                measurementMoment,
                ...benchmarkApiParams,
            },
        ).pipe(
            map(outcomes =>
                outcomes.map(outcome => {
                    switch (outcome.filterType) {
                        case "Range":
                            return {
                                filterType: "Range",
                                filterName: outcome.filterName,
                                comparisonData: outcome.comparisonData.map(comparison => ({
                                    filterValue: comparison.filterValue,
                                    outcome: {
                                        first: this._getDataOfProvider(
                                            comparison.outcome,
                                            selectedProviders,
                                            0,
                                        ),
                                        second: this._getDataOfProvider(
                                            comparison.outcome,
                                            selectedProviders,
                                            1,
                                        ),
                                        third:
                                            selectedProviders.length > 2
                                                ? this._getDataOfProvider(
                                                      comparison.outcome,
                                                      selectedProviders,
                                                      2,
                                                  )
                                                : undefined,
                                    },
                                })),
                            };
                        case "HistogramRange":
                            return {
                                filterType: "HistogramRange",
                                filterName: outcome.filterName,
                                comparisonData: outcome.comparisonData.map(comparison => ({
                                    filterValue: comparison.filterValue,
                                    outcome: {
                                        first: this._getDataOfProvider(
                                            comparison.outcome,
                                            selectedProviders,
                                            0,
                                        ),
                                        second: this._getDataOfProvider(
                                            comparison.outcome,
                                            selectedProviders,
                                            1,
                                        ),
                                        third:
                                            selectedProviders.length > 2
                                                ? this._getDataOfProvider(
                                                      comparison.outcome,
                                                      selectedProviders,
                                                      2,
                                                  )
                                                : undefined,
                                    },
                                })),
                            };
                        case "String":
                            return {
                                filterType: "String",
                                filterName: outcome.filterName,
                                comparisonData: outcome.comparisonData.map(comparison => ({
                                    filterValue: comparison.filterValue,
                                    outcome: {
                                        first: this._getDataOfProvider(
                                            comparison.outcome,
                                            selectedProviders,
                                            0,
                                        ),
                                        second: this._getDataOfProvider(
                                            comparison.outcome,
                                            selectedProviders,
                                            1,
                                        ),
                                        third:
                                            selectedProviders.length > 2
                                                ? this._getDataOfProvider(
                                                      comparison.outcome,
                                                      selectedProviders,
                                                      2,
                                                  )
                                                : undefined,
                                    },
                                })),
                            };
                        default:
                        case "Category":
                            return {
                                filterType: "Category",
                                filterName: outcome.filterName,
                                comparisonData: outcome.comparisonData.map(comparison => ({
                                    filterValue: comparison.filterValue,
                                    outcome: {
                                        first: this._getDataOfProvider(
                                            comparison.outcome,
                                            selectedProviders,
                                            0,
                                        ),
                                        second: this._getDataOfProvider(
                                            comparison.outcome,
                                            selectedProviders,
                                            1,
                                        ),
                                        third:
                                            selectedProviders.length > 2
                                                ? this._getDataOfProvider(
                                                      comparison.outcome,
                                                      selectedProviders,
                                                      2,
                                                  )
                                                : undefined,
                                    },
                                })),
                            };
                    }
                }),
            ),
        );
    }

    /**
     * Patient Counts
     */
    getPatientsCount(
        registryId: string,
        subsetId: string,
        organizationId: number,
        timeRange?: TimeRange,
        filters?: FilterParams,
    ): Observable<IPatientCount> {
        return this.getFiltered<IPatientCount>(
            `${registryId}/${subsetId}/patientscount`,
            organizationId,
            filters,
            {
                timeRange,
            },
        );
    }

    getPatientTrend(
        registryId: string,
        subsetId: string,
        interval: TrendInterval,
        organizationId: number,
        filters?: FilterParams,
    ): Observable<ITrendMoment[]> {
        return this.getFiltered<ITrendMoment[]>(
            `${registryId}/${subsetId}/patientscount/trend/${interval}`,
            organizationId,
            filters,
        );
    }

    /**
     * Patient List
     */
    getPatientsList(
        registryId: string,
        subsetId: string,
        organizationId: number,
        timeRange?: TimeRange,
        filters?: FilterParams,
        offset?: number,
        count?: number,
        sortBy?: string,
        sortType?: ISortDirection,
    ): Observable<PatientList> {
        return this.getFiltered<PatientList>(
            `${registryId}/${subsetId}/patientslist`,
            organizationId,
            filters,
            {
                timeRange,
                offset,
                count,
                sortBy,
                sortType,
            },
        );
    }

    /**
     * Patient export
     */
    getPatientExport(
        registryId: string,
        subsetId: string,
        organizationId: number,
        params: {
            language: string;
        },
        timeRange: TimeRange,
        filters?: FilterParams,
    ): Observable<HttpResponse<Blob>> {
        let filtersQuery: Record<string, string> = {};
        if (filters?.length) {
            filtersQuery = this._getFiltersQueryParamsString(filters, "searchCriteria");
        }
        let otherParams: Record<string, string> = {};
        if (
            timeRange?.minYear !== null &&
            timeRange?.maxYear !== null &&
            timeRange?.minMonth !== null &&
            timeRange?.maxMonth
        ) {
            otherParams = this._getOtherQueryParams({ timeRange });
        }
        return this._get<Blob>(`${registryId}/${subsetId}/patientslist/report`, {
            params: {
                ...params,
                ...filtersQuery,
                ...otherParams,
            },
            headers: {
                [ORG_ID_HEADER]: organizationId.toString(),
            },
            responseType: "blob",
            observe: "response",
        });
    }

    /**
     * Data for export
     */
    getDataExport(
        registryId: string,
        subsetId: string,
        interval: TrendInterval,
        organizationId: number,
        selectedProviders: Provider[],
        benchmarkApiParams: BenchmarkParams,
        timeRange?: TimeRange,
        filters?: FilterParams,
    ): Observable<IDataExportPageUniversal[]> {
        return this.getFiltered<IDataExportPage[]>(
            `${registryId}/${subsetId}/dataexport/${interval}`,
            organizationId,
            filters,
            { timeRange, ...benchmarkApiParams },
        ).pipe(
            map(pages =>
                pages.map(page => ({
                    name: page.name,
                    sections: page.sections.map(section => ({
                        name: section.name,
                        outcomes: section.outcomes.map(outcome => ({
                            id: outcome.id,
                            outcome: {
                                unit: outcome.outcome.unit,
                                calculationType: outcome.outcome.calculationType,
                                formatter: outcome.outcome.formatter,
                                option: outcome.outcome.option,
                                outcomeId: outcome.outcome.outcomeId,
                                sharedProviders: outcome.outcome.sharedProviders,
                                medicalParameterId: outcome.outcome.medicalParameterId,
                                maxValue: outcome.outcome.maxValue,
                                visualType: outcome.outcome.visualType,
                                decimalPoints: outcome.outcome.decimalPoints,
                                outcomeType: outcome.outcome.outcomeType,
                                funnelPlotConfidenceInterval:
                                    outcome.outcome.funnelPlotConfidenceInterval,

                                first: this._getDataOfProvider(
                                    outcome.outcome,
                                    selectedProviders,
                                    0,
                                ),
                                second: this._getDataOfProvider(
                                    outcome.outcome,
                                    selectedProviders,
                                    1,
                                ),
                                third:
                                    selectedProviders.length > 2
                                        ? this._getDataOfProvider(
                                              outcome.outcome,
                                              selectedProviders,
                                              2,
                                          )
                                        : undefined,
                            },

                            trend: outcome.trend.map(trend => ({
                                year: trend.year,
                                quarter: trend.quarter,
                                month: trend.month,
                                anonymized: trend.anonymized,
                                first: this._getDataOfProvider(trend, selectedProviders, 0),
                                second: this._getDataOfProvider(trend, selectedProviders, 1),
                                third:
                                    selectedProviders.length > 2
                                        ? this._getDataOfProvider(trend, selectedProviders, 2)
                                        : undefined,
                            })),
                            confidenceType: outcome.confidenceType,
                            otherProviders: outcome.otherProviders,
                        })),
                    })),
                })),
            ),
        );
    }

    getSectionFilters(
        registryId: string,
        subsetId: string,
        pageId: string,
        sectionId: string,
        organizationId: number,
        filters?: FilterParams,
    ): Observable<ISectionFilter> {
        return this.getFiltered<ISectionFilter>(
            `${registryId}/${subsetId}/${pageId}/${sectionId}/filters`,
            organizationId,
            filters,
        );
    }

    getDistributions(
        registryId: string,
        subsetId: string,
        organizationId: number,
        distributionParameter: string,
        filters?: FilterParams,
    ): Observable<IDistribution> {
        return this.getFiltered<IDistribution>(
            `${registryId}/${subsetId}/${distributionParameter}/distribution`,
            organizationId,
            filters,
        );
    }

    /**
     * Other
     */
    getFiltered<T>(
        url: string,
        organizationId: number,
        filters?: FilterParams,
        nonFilterParams?: NonFilterParams,
    ): Observable<T> {
        let filtersQuery: Record<string, string> = {};
        let otherParams: Record<string, string> = {};
        const otherQueryParams = { ...nonFilterParams };
        if (nonFilterParams) {
            otherParams = this._getOtherQueryParams(otherQueryParams);
        }

        if (filters?.length) {
            filtersQuery = this._getFiltersQueryParamsString(filters, "searchCriteria");
        }

        return this._get<T>(url, {
            headers: {
                [ORG_ID_HEADER]: organizationId.toString(),
            },
            params: {
                ...filtersQuery,
                ...otherQueryParams,
                ...otherParams,
            },
        });
    }

    private _getOtherQueryParams(otherParams: NonFilterParams): Record<string, string> {
        const params: Record<string, string> = {};

        const connectBenchmarkProviders = otherParams.connectBenchmarkProviders;
        if (connectBenchmarkProviders) {
            const paramValue = this._getCategoryQueryString({
                type: "category",
                id: "connectBenchmarkProviders",
                values: connectBenchmarkProviders,
            });
            params["connectBenchmarkProviders"] = paramValue;
            delete otherParams.connectBenchmarkProviders;
        }

        const regions = otherParams.regions;
        if (regions) {
            const paramValue = this._getCategoryQueryString({
                type: "category",
                id: "regions",
                values: [regions],
            });
            params["regions"] = paramValue;
            delete otherParams.regions;
        }

        const years = otherParams.timeRange;
        if (years?.minYear && years?.maxYear && years?.minMonth && years?.maxMonth) {
            const paramValue = this._getTimeRangeQueryString({
                type: "timeRange",
                id: "timeRange",
                minYear: years.minYear,
                maxYear: years.maxYear,
                minMonth: years.minMonth,
                maxMonth: years.maxMonth,
            });
            params["timeRange"] = paramValue;
        }
        if (years) {
            delete otherParams.timeRange;
        }

        const providerType = otherParams.providerType;
        if (providerType) {
            const paramValue = this._getStringQueryString({
                type: "string",
                id: "providerType",
                value: providerType,
            });
            params["providerType"] = paramValue;
            delete otherParams.providerType;
        }

        return params;
    }

    private _getFiltersQueryParamsString(
        params: FilterParams,
        dictionaryName: string,
    ): Record<string, string> {
        const filterParams: Record<string, string> = {};
        params.forEach(param => {
            let filterValue: string;
            switch (param.type) {
                case "category":
                    filterValue = this._getCategoryQueryString(param);
                    break;
                case "range":
                    filterValue = this._getRangeQueryString(param);
                    break;
                case "string":
                    filterValue = this._getStringQueryString(param);
                    break;
                case "number":
                    filterValue = this._getNumberQueryString(param);
                    break;
                default:
                    filterValue = "";
            }
            const filterKey = `${dictionaryName}[${param.id}]`;
            filterParams[filterKey] = filterValue;
        });

        return filterParams;
    }

    private _getCategoryQueryString(params: CategoryQueryParams): string {
        const filterValuesQuery = params.values.join(SEPARATORS.values);
        return filterValuesQuery;
    }

    private _getRangeQueryString(params: RangeQueryParams): string {
        const filterValuesQuery = params.min + SEPARATORS.range + params.max;
        return filterValuesQuery;
    }

    private _getTimeRangeQueryString(params: TimeRangeQueryParams): string {
        const filterValuesQuery =
            params.minYear +
            SEPARATORS.items +
            params.minMonth +
            SEPARATORS.range +
            params.maxYear +
            SEPARATORS.items +
            params.maxMonth;
        return filterValuesQuery;
    }

    private _getStringQueryString(params: StringQueryParams): string {
        return params.value;
    }

    private _getNumberQueryString(params: NumberQueryParams): string {
        return params.value.toString();
    }

    /**
     * Status
     */
    getStatus(): Observable<IStatus> {
        return this._get<IStatus>(`/`);
    }

    private _getDataOfProvider(
        outcome: IOutcome | IFilterOption | IOutcomeTrend,
        selectedProviders: Provider[],
        order: number,
    ): IOutcomeWithSource {
        const provider =
            selectedProviders.length > order ? selectedProviders[order] : defaultProviders[order];

        if (provider === "ownProvider" && outcome.ownProvider !== undefined) {
            return {
                sourceType: "ownProvider",
                data: outcome.ownProvider ?? undefined,
            };
        } else if (provider === "regionalBenchmark" && outcome.regionalBenchmark !== undefined) {
            return {
                sourceType: "regionalBenchmark",
                data: outcome.regionalBenchmark,
            };
        } else if (
            provider === "providerTypeBenchmark" &&
            outcome.providerTypeBenchmark !== undefined
        ) {
            return {
                sourceType: "providerTypeBenchmark",
                data: outcome.providerTypeBenchmark,
            };
        } else if (provider === "connectBenchmark" && outcome.connectBenchmark !== undefined) {
            return {
                sourceType: "connectBenchmark",
                data: outcome.connectBenchmark,
            };
        } else if (
            provider === "internationalBenchmark" &&
            outcome.internationalBenchmark !== undefined
        ) {
            return {
                sourceType: "internationalBenchmark",
                data: outcome.internationalBenchmark,
            };
        } else {
            return {
                sourceType: "nationalBenchmark",
                data: outcome.nationalBenchmark,
            };
        }
    }

    private _getEventAnalysisWithSource(
        interval: IEventAnalysisInterval,
        benchmarkOnly = false,
    ): IEventAnalysisIntervalReferenceWithSource {
        if (interval.ownProvider !== undefined && !benchmarkOnly) {
            return {
                sourceType: "ownProvider",
                data: interval.ownProvider ?? undefined,
            };
        } else if (interval.providerTypeBenchmark !== undefined) {
            return {
                sourceType: "providerTypeBenchmark",
                data: interval.providerTypeBenchmark,
            };
        } else if (interval.connectBenchmark !== undefined) {
            return {
                sourceType: "connectBenchmark",
                data: interval.connectBenchmark,
            };
        } else {
            return {
                sourceType: "nationalBenchmark",
                data: interval.nationalBenchmark,
            };
        }
    }

    private _getSankeyWithSource(
        outcome: ISankey,
        selectedProviders: Provider[],
        order: number,
    ): IOutcomeWithSource {
        const provider =
            selectedProviders.length > order ? selectedProviders[order] : defaultProviders[order];

        if (provider === "ownProvider" && outcome.ownProvider !== undefined) {
            return {
                sourceType: "ownProvider",
                data: (outcome.ownProvider as ReferenceOutcome) ?? undefined,
            };
        } else if (provider === "regionalBenchmark" && outcome.regionalBenchmark !== undefined) {
            return {
                sourceType: "regionalBenchmark",
                data: outcome.regionalBenchmark as ReferenceOutcome,
            };
        } else if (
            provider === "providerTypeBenchmark" &&
            outcome.providerTypeBenchmark !== undefined
        ) {
            return {
                sourceType: "providerTypeBenchmark",
                data: outcome.providerTypeBenchmark as ReferenceOutcome,
            };
        } else if (provider === "connectBenchmark" && outcome.connectBenchmark !== undefined) {
            return {
                sourceType: "connectBenchmark",
                data: outcome.connectBenchmark as ReferenceOutcome,
            };
        } else if (
            provider === "internationalBenchmark" &&
            outcome.internationalBenchmark !== undefined
        ) {
            return {
                sourceType: "internationalBenchmark",
                data: outcome.internationalBenchmark as ReferenceOutcome,
            };
        } else {
            return {
                sourceType: "nationalBenchmark",
                data: outcome.nationalBenchmark as ReferenceOutcome,
            };
        }
    }
}
